<template>
  <v-row>
    <styling @info="setInfo"/>
    <v-col cols="12" lg="5" xl="6" class="d-flex align-center">
      <v-container>
        <div class="pa-7 pa-sm-12">
          <v-row v-if="$vuetify.breakpoint.mdAndDown && logo">
            <v-col class="text-center">
              <img style="width: 35%" :src="logo" />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" lg="9" xl="6">
              <v-btn text @click="$router.push('/login')">
                <v-icon left>mdi-arrow-left</v-icon>
                {{ $store.getters.translate("login") }}
              </v-btn>
              <h2 class="font-weight-bold mt-4 blue-grey--text text--darken-2 mb-5">
                {{ $store.getters.translate("reset_your_password") }}
              </h2>
              <v-form ref="form" v-model="valid" lazy-validation>
                <v-text-field
                  v-model="email"
                  :label="$store.getters.translate('email')"
                  required
                  outlined
                  type="text"/>
                <div class="d-block d-sm-flex align-center mb-4 mb-sm-0"/>
                <v-btn
                    @click="submit"
                    :loading="loading"
                    :disabled="!valid"
                    color="info"
                    block
                    class="mr-4"
                    submit>
                  {{ $store.getters.translate("send") }}
                </v-btn>
              </v-form>
            </v-col>
          </v-row>
        </div>
      </v-container>
    </v-col>
  </v-row>
</template>

<script>
import Styling from "./includable/Styling";
export default {
  name: "FullLogin",
  title: process.env.VUE_APP_TITLE ? process.env.VUE_APP_TITLE : "Perscriptum",
  components: {
    Styling,
  },
  data: () => ({
    valid: true,
    email: "",
    loading: false,
    logo: null,
  }),
  mounted() {
    document.title = process.env.VUE_APP_TITLE;
    this.$store.dispatch("refreshTranslations");
  },
  methods: {
    submit() {
      this.loading = true;
      if (this.$refs.form.validate(true)) {
        this.$http
          .post(this.$store.getters.appUrl + "v2/resetpassword", { email: this.email })
          .then(() => {
            this.loading = false;
            this.$toasted.success(this.$store.getters.translate("check_your_email"));
            this.$router.push("/login");
          })
          .catch((error) => {
            this.loading = false;
            if (+error.response.status === 404) {
              this.$toasted.error(this.$store.getters.translate("password_has_already_been_reset"));
            } else {
              this.$store.getters.translate("something_went_wrong");
            }
          });
      }
    },
    setInfo(data) {
      this.logo = data.logo;
    },
  },
};
</script>
