import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VForm } from 'vuetify/lib/components/VForm';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VRow,[_c('styling',{on:{"info":_vm.setInfo}}),_c(VCol,{staticClass:"d-flex align-center",attrs:{"cols":"12","lg":"5","xl":"6"}},[_c(VContainer,[_c('div',{staticClass:"pa-7 pa-sm-12"},[(_vm.$vuetify.breakpoint.mdAndDown && _vm.logo)?_c(VRow,[_c(VCol,{staticClass:"text-center"},[_c('img',{staticStyle:{"width":"35%"},attrs:{"src":_vm.logo}})])],1):_vm._e(),_c(VRow,[_c(VCol,{attrs:{"cols":"12","lg":"9","xl":"6"}},[_c(VBtn,{attrs:{"text":""},on:{"click":function($event){return _vm.$router.push('/login')}}},[_c(VIcon,{attrs:{"left":""}},[_vm._v("mdi-arrow-left")]),_vm._v(" "+_vm._s(_vm.$store.getters.translate("login"))+" ")],1),_c('h2',{staticClass:"font-weight-bold mt-4 blue-grey--text text--darken-2 mb-5"},[_vm._v(" "+_vm._s(_vm.$store.getters.translate("reset_your_password"))+" ")]),_c(VForm,{ref:"form",attrs:{"lazy-validation":""},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c(VTextField,{attrs:{"label":_vm.$store.getters.translate('email'),"required":"","outlined":"","type":"text"},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}}),_c('div',{staticClass:"d-block d-sm-flex align-center mb-4 mb-sm-0"}),_c(VBtn,{staticClass:"mr-4",attrs:{"loading":_vm.loading,"disabled":!_vm.valid,"color":"info","block":"","submit":""},on:{"click":_vm.submit}},[_vm._v(" "+_vm._s(_vm.$store.getters.translate("send"))+" ")])],1)],1)],1)],1)])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }